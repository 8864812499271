export const MAIN_CATEGORY_URL_KEY_PREFIX = 'main-category-';
export const MAIN_CATEGORY_WOMEN_URL_KEY_SUFFIX = 'women';
export const MAIN_CATEGORY_MEN_URL_KEY_SUFFIX = 'men';
export const MAIN_CATEGORY_CHILD_URL_KEY_SUFFIX = 'child';

export const getMainCategoryUrlKeyBySuffix = suffix => `${MAIN_CATEGORY_URL_KEY_PREFIX}${suffix}`;

export const MAIN_CATEGORY_WOMEN_URL_KEY = getMainCategoryUrlKeyBySuffix(
    MAIN_CATEGORY_WOMEN_URL_KEY_SUFFIX
);
export const MAIN_CATEGORY_MEN_URL_KEY = getMainCategoryUrlKeyBySuffix(
    MAIN_CATEGORY_MEN_URL_KEY_SUFFIX
);
export const MAIN_CATEGORY_CHILD_URL_KEY = getMainCategoryUrlKeyBySuffix(
    MAIN_CATEGORY_CHILD_URL_KEY_SUFFIX
);

export default {
    getMainCategoryUrlKeyBySuffix,
    MAIN_CATEGORY_URL_KEY_PREFIX,
    MAIN_CATEGORY_WOMEN_URL_KEY_SUFFIX,
    MAIN_CATEGORY_MEN_URL_KEY_SUFFIX,
    MAIN_CATEGORY_CHILD_URL_KEY_SUFFIX,
    MAIN_CATEGORY_WOMEN_URL_KEY,
    MAIN_CATEGORY_MEN_URL_KEY,
    MAIN_CATEGORY_CHILD_URL_KEY,
};
